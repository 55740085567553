import React, { Component } from 'react';
import axios from 'axios'
import MapBox from '../map-section/map';
import TextInstructions from '../infographic/steps';
import FAQ from '../faq-section/faq';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import { accessToken } from 'mapbox-gl';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

var hoverId = null;
var currentCanadaId = -1;
var loaded = 0;
var firstLoad = true;
var psapsLoaded = false;

const stateCodes = new Map([
  ['01', 'al'],['02', 'ak'],['04', 'az'],['05', 'ar'],['06', 'ca'],['08', 'co'],['09', 'ct'],
  ['10', 'de'],['11', 'dc'],['12', 'fl'],['13', 'ga'],['15', 'hi'],['16', 'id'],['17', 'il'],
  ['18', 'in'],['19', 'ia'],['20', 'ks'],['21', 'ky'],['22', 'la'],['23', 'me'],['24', 'md'],
  ['25', 'ma'],['26', 'mi'],['27', 'mn'],['28', 'ms'],['29', 'mo'],['30', 'mt'],['31', 'ne'],
  ['32', 'nv'],['33', 'nh'],['34', 'nj'],['35', 'nm'],['36', 'ny'],['37', 'nc'],['38', 'nd'],
  ['39', 'oh'],['40', 'ok'],['41', 'or'],['42', 'pa'],['44', 'ri'],['45', 'sc'],['46', 'sd'],
  ['47', 'tn'],['48', 'tx'],['49', 'ut'],['50', 'vt'],['51', 'va'],['53', 'wa'],['54', 'wv'],
  ['55', 'wi'],['56', 'wy'],['72', 'pr']
])

function load_backup() {
  var backup = require('../backups/compatibleBackup.json');
  return backup;
}

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          psapMasterRegistry: [],
          psapMap: new Map(),
          countyArray: [],
          countyMap: new Map(),
          allCounties: new Map(),
          lastUpdated: new Map(),
          language: 'en',
          time: 0,
          compatiblePSAPS: 0,
          countyCount: 0
        }
        this.styleFeature = this.styleFeature.bind(this);
        this.addMapLayers = this.addMapLayers.bind(this);
        this.hoverPopup = this.hoverPopup.bind(this);
        this.toggleMapColors = this.toggleMapColors.bind(this);
        this.loadLocalMap = this.loadLocalMap.bind(this);
      }
    
      getLastUpdatedDates() {
        axios.get("https://accessos-map-backend.herokuapp.com/getUpdateDates").then(
          updateDates => {
            var dates = updateDates.data;
            this.state.lastUpdated.set("compatiblePSAPsDate", dates.compatible);
            this.state.lastUpdated.set("totalPSAPsDate", dates.master);
          }
        ).catch(errors => {
          console.log("Error getting last updated dates, using backup.");
          var lastUpdatedBackup = require('../backups/updateDatesBackup.json')
          this.state.lastUpdated.set("compatiblePSAPsDate", lastUpdatedBackup.compatible);
          this.state.lastUpdated.set("totalPSAPsDate", lastUpdatedBackup.master);
        })
      }
    
      loadLocalMap(map, data) {
        let compatibleCount = 0;
        let counties = 0;
        for(let i = 0; i < data.length; i++) {
          counties++;
          let state = data[i]["State"];
          let county = data[i]["County"];
          if(county.includes("-")) {
            county = county.replace("-", ".");
          }
          let text911Status = data[i]["TextTo911"];
          let compatiblePSAPs = data[i]["CompatiblePSAPs"];
          let incompatiblePSAPs = data[i]["IncompatiblePSAPs"];
          let PSAPs = data[i]["PSAPList"];
          compatibleCount += compatiblePSAPs;
          let countyInfo = {
            Status: text911Status,
            CompatibleCount: compatiblePSAPs,
            IncompatibleCount: incompatiblePSAPs,
            PSAPsList: PSAPs
          }
          if(!map.has(state)) {
            map.set(state, new Map());
          }
          map.get(state).set(county, countyInfo);
        }
        this.setState({ compatiblePSAPS: compatibleCount, countyCount: counties })
      }
    
      componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        if (firstLoad) {
          this.getLastUpdatedDates();
          axios.get('https://accessos-map-backend.herokuapp.com/documents').then(
            result => {
              this.setState({
                countyArray: result.data
              })      
              if (this.state.countyArray.length < 1400) { //1417 usual length
                console.log('Activating backup ... data size: ' + this.state.countyArray.length);
                var backup = load_backup();
                this.loadLocalMap(this.state.countyMap, backup);
              } else {
                this.loadLocalMap(this.state.countyMap, this.state.countyArray);
              }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            error => {
              console.log('Error getting database file, using backup.')
              var backup = load_backup();
              this.loadLocalMap(this.state.countyMap, backup);
            }
          ).finally(() => {
              // If you encounter errors or discrepencies with database information, uncomment these logs for debugging
              // console.log('--------Locally Stored Database--------');
              // console.log(this.state.countyMap);
              axios.get('https://accessos-map-backend.herokuapp.com/masterDocument').then(
                registry => {
                  this.state.psapMap.set('Total PSAPs', registry.data.Total)
                  this.state.psapMap.set('Added', registry.data.Added)
                  this.state.psapMap.set('Modified', registry.data.Modified)
                  this.state.psapMap.set('No Change', registry.data.NoChange)
                  this.state.psapMap.set('Secondary', registry.data.Secondary)
                  this.state.psapMap.set('Orphaned', registry.data.Orphaned)
                  this.state.psapMap.set('Compatible PSAPs', this.state.compatiblePSAPS);
                }, error => {
                  console.log("Error getting master registry file, using backup.");
                  var masterBackup = require("../backups/masterBackup.json");
                  this.state.psapMap.set('Total PSAPs', masterBackup.Total)
                  this.state.psapMap.set('Added', masterBackup.Added)
                  this.state.psapMap.set('Modified', masterBackup.Modified)
                  this.state.psapMap.set('No Change', masterBackup.NoChange)
                  this.state.psapMap.set('Secondary', masterBackup.Secondary)
                  this.state.psapMap.set('Orphaned', masterBackup.Orphaned)
                  this.state.psapMap.set('Compatible PSAPs', this.state.compatiblePSAPS);
                }).finally(() => {
                  // If you encounter errors or discrepencies with database information, uncomment these logs for debugging
                  // console.log('------------Master Registry------------');
                  // console.log(this.state.psapMasterRegistry);
                  // console.log(this.state.psapMap);
                  // console.log(this.state.countyMap);
                  psapsLoaded = true;
              })
            }
          )
          firstLoad = false;
        }
      }
    
      componentWillUnmount() {
        clearInterval(this.interval);
      }
    
      styleFeature(map, countyMap) {
        map.on('sourcedata', function (e) {
          var features = map.querySourceFeatures('USA');
          if (features.length > 0) {
            for (var feature of features) {
              var stateNum = feature.properties.STATE;
              var stateName = stateCodes.get(stateNum);
              var countyName = feature.properties.NAME;
              var countyCheck = feature.properties.LSAD;
              var textCapId = feature.id;
              if(countyName != undefined && stateName != undefined) { 
                countyName = countyName.toLowerCase(); 
                if(countyName.startsWith('kenai')) {
                  countyName = "kenai peninsula borough";
                } else if(countyName.includes('\'')) {
                  countyName = countyName.split('\'')[0];
                }
                let currentStateMap = countyMap.get(stateName);
                if (currentStateMap != undefined) {
                  if (currentStateMap.has("all counties") || 
                      currentStateMap.has("for entire commonwealth") ||
                      currentStateMap.has("") ) {
                      map.setFeatureState(
                        { source: 'USA', id: textCapId },
                        { textcapable: true })
                  } else {
                    var county = currentStateMap.get(countyName);
                    if (county != undefined) {
                      if (county["Status"] == 1) {
                        map.setFeatureState(
                          { source: 'USA', id: textCapId },
                          { textcapable: true });
                      } else {
                        map.setFeatureState(
                          { source: 'USA', id: textCapId },
                          { textcapable: false });
                      }
                    }
                  } 
                  
                }
              } 
            }
          }
          if (e.isSourceLoaded) {
            loaded += 1;
          }
        });
      }
    
      addMapLayers(map) {
        
        map.on('load', () => {
          const layers = map.getStyle().layers;
          let firstSymbolId;
          for (const layer of layers) {
            if (layer.type === 'symbol') {
              firstSymbolId = layer.id;
              break;
            }
          }
    
          map.addSource('USA', {
            'type': 'geojson',
            'data': 'map.geojson',
            'generateId': true // This ensures that all features have unique IDs
          });
    
          map.addSource('Canada', {
            'type': 'geojson',
            'data': 'canada.geojson',
            'generateId': true
          });
    
          map.addLayer({
            'id': 'canada-borders',
            'type': 'line',
            'source': 'Canada',
            'layout': {},
            'paint': {
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                'white',
                '#AAAAAA'
              ],
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                0.5
              ],
              'line-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.7
              ],
            }
          }, firstSymbolId);
    
          map.addLayer({
            'id': 'state-borders',
            'type': 'line',
            'source': 'USA',
            'layout': {},
            'paint': {
              'line-color': [
                'case',
                ['all', ['==', ['feature-state', 'textcapable'], true], ['==', ['feature-state', 'hover'], true]],
                'white',
                ['==', ['feature-state', 'textcapable'], true],
                '#3D3D3D',
                ['==', ['feature-state', 'hover'], true],
                'white',
                '#AAAAAA'
              ],
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                0.5
              ],
              'line-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.7
              ]
            },
          }, 'canada-borders');
    
          map.addLayer({
            'id': 'Not Available Areas',
            'type': 'fill',
            'source': 'USA',
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'textcapable'], false],
                '#ededed',
                '#DF4154',
              ]
            }
          }, 'national-park');
    
          map.addLayer({
            'id': 'Available Areas',
            'type': 'fill',
            'source': 'USA',
            'layout': {
              'visibility': 'visible'
            },
            'paint': {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'textcapable'], false],
                // '#587BBE',
                '#03a63c',
                '#ededed',
              ]
            }
          }, 'Not Available Areas');
    
          map.addLayer({
            'id': 'canada-fill-red',
            'type': 'fill',
            'source': 'Canada',
            'layout': {
              'visibility': 'none'
            },
            'paint': {
              'fill-color': '#DF4154'
            }
          }, 'Available Areas');
    
          map.addLayer({
            'id': 'canada-fill-green',
            'type': 'fill',
            'source': 'Canada',
            'layout': {
              'visibility': 'visible'
            },
            'paint': {
              'fill-color': '#ededed',
            }
          }, 'canada-fill-red');
        });
      }
    
      hoverPopup(map) {
        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });
        var canada_popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: false,
          closeOnMove: true
        });
        map.on('mousemove', 'Available Areas', function (e) {
          currentCanadaId = -1;
          if (canada_popup.isOpen()) {
            canada_popup.remove();
          }
          var textcap = e.features[0].state.textcapable ? 'Yes' : 'No';
          popup.setLngLat(e.lngLat.wrap()).setHTML(e.features[0].properties.NAME + ' County' + '<br>' + 'Text to 911: ' + textcap).addTo(map);
          if (e.features.length > 0) {
            if (hoverId >= 0) {
              map.setFeatureState(
                { source: 'USA', id: hoverId },
                { hover: false }
              );
              map.setFeatureState(
                { source: 'Canada', id: hoverId },
                { hover: false }
              )
            }
            hoverId = e.features[0].id
            map.setFeatureState(
              { source: 'USA', id: hoverId },
              { hover: true }
            );
          }
        });
    
        map.on('mousemove', 'Not Available Areas', function (e) {
          currentCanadaId = -1;
          if (canada_popup.isOpen()) {
            canada_popup.remove();
          }
          var textcap = e.features[0].state.textcapable ? 'Yes' : 'No';
          popup.setLngLat(e.lngLat.wrap()).setHTML(e.features[0].properties.NAME + ' County' + '<br>' + 'Text to 911: ' + textcap).addTo(map);
          if (e.features.length > 0) {
            if (hoverId >= 0) {
              map.setFeatureState(
                { source: 'USA', id: hoverId },
                { hover: false }
              );
              map.setFeatureState(
                { source: 'Canada', id: hoverId },
                { hover: false }
              )
            }
            hoverId = e.features[0].id
            map.setFeatureState(
              { source: 'USA', id: hoverId },
              { hover: true }
            );
          }
        });
    
        map.on('mousemove', 'canada-fill-green', function (e) {
          if (currentCanadaId != e.features[0].id) {
            currentCanadaId = e.features[0].id;
            canada_popup.setLngLat(e.lngLat).setHTML(e.features[0].properties.name + "<br>" +
              "Text to 911: no*<br><a style='color:blue; text-decoration: underline;' href='https://www.textwith911.ca/en/faqs/#a4'>*more info</a>"
            );
            canada_popup.addTo(map);
          }
    
          if (e.features.length > 0) {
            if (hoverId >= 0) {
              map.setFeatureState(
                { source: 'Canada', id: hoverId },
                { hover: false }
              );
              map.setFeatureState(
                { source: 'USA', id: hoverId },
                { hover: false }
              );
            }
            hoverId = e.features[0].id;
            map.setFeatureState(
              { source: 'Canada', id: hoverId },
              { hover: true }
            );
          }
        });
    
        map.on('mousemove', 'canada-fill-red', function (e) {
          if (currentCanadaId != e.features[0].id) {
            currentCanadaId = e.features[0].id;
            canada_popup.setLngLat(e.lngLat).setHTML(e.features[0].properties.name + "<br>" +
              "Text to 911: no*<br><a style='color:blue; text-decoration: underline;' href='https://www.textwith911.ca/en/faqs/#a4'>*more info</a>"
            );
            canada_popup.addTo(map);
          }
    
          if (e.features.length > 0) {
            if (hoverId >= 0) {
              map.setFeatureState(
                { source: 'Canada', id: hoverId },
                { hover: false }
              );
              map.setFeatureState(
                { source: 'USA', id: hoverId },
                { hover: false }
              );
            }
            hoverId = e.features[0].id;
            map.setFeatureState(
              { source: 'Canada', id: hoverId },
              { hover: true }
            );
          }
        });
    
        map.on('mouseleave', 'Not Available Areas', function (e) {
          if (hoverId >= 0) {
            map.setFeatureState(
              { source: 'USA', id: hoverId },
              { hover: false }
            );
            map.setFeatureState(
              { source: 'Canada', id: hoverId },
              { hover: false }
            )
          }
          hoverId = null;
          popup.remove();
        });
    
        map.on('mouseleave', 'Available Areas', function (e) {
          if (hoverId >= 0) {
            map.setFeatureState(
              { source: 'Canada', id: hoverId },
              { hover: false }
            );
            map.setFeatureState(
              { source: 'USA', id: hoverId },
              { hover: false }
            );
          }
          hoverId = null;
          popup.remove();
        });
    
        map.on('mouseleave', 'canada-fill-green', function (e) {
          currentCanadaId = -1;
          if (hoverId >= 0) {
            map.setFeatureState(
              { source: 'Canada', id: hoverId },
              { hover: false }
            );
            map.setFeatureState(
              { source: 'USA', id: hoverId },
              { hover: false }
            );
          }
          hoverId = null;
          popup.remove();
        });
      }
    
      toggleMapColors(map) {
        var btnContainer = document.getElementById('myDiv');
        var btns = btnContainer.getElementsByClassName('btn');
        for (var i = 0; i < btns.length; i++) {
          btns[i].addEventListener("click", function () {
            if (this.id === 'aa') {
              if (!this.className.includes(' activeAvailable')) {
                this.className += ' activeAvailable';
              }
              var nextBtn = document.getElementById('na');
              nextBtn.className = 'toggles btn btnStyle rightBtn';
              map.setLayoutProperty('Available Areas', 'visibility', 'visible');
              map.setLayoutProperty('Not Available Areas', 'visibility', 'none');
              map.setLayoutProperty('canada-fill-green', 'visibility', 'visible');
              map.setLayoutProperty('canada-fill-red', 'visibility', 'none');
            } else if (this.id === 'na') {
              if (!this.className.includes(' activeUnavailable')) {
                this.className += ' activeUnavailable';
              }
              var nextBtn = document.getElementById('aa');
              nextBtn.className = 'toggles btn btnStyle leftBtn';
              map.setLayoutProperty('Available Areas', 'visibility', 'none');
              map.setLayoutProperty('Not Available Areas', 'visibility', 'visible');
              map.setLayoutProperty('canada-fill-green', 'visibility', 'none');
              map.setLayoutProperty('canada-fill-red', 'visibility', 'visible');
            }
          });
        }
      }
    render() { 
        var loading = (loaded >= 2) ? false : true;
        return ( <div id="site">
            {/** Component used to display the map, search bar, and color swap buttons */}
          <MapBox
              onMapLoad={map => {
                this.map = map;
                this.addMapLayers(map);
                this.toggleMapColors(map);
                this.styleFeature(map, this.state.countyMap);
                this.hoverPopup(map);
              }}
              countsMap={this.state.psapMap}
              countsLoaded={psapsLoaded}
              countyMap={this.state.countyMap}
              isLoading={loading}
          />

          {/** Displays the date the FCC file used to color the map was last updated */}
          <div className="information" id="compatibleDate">
              Last updated  <span style={{ color: '#df4154' }}>{this.state.lastUpdated.get("compatiblePSAPsDate")}</span> based
               on the <a href="https://www.fcc.gov/general/psap-text-911-readiness-and-certification-form">
                <span style={{ color: 'blue' }}>FCC's</span> Text to 911 PSAP registry
              </a>
          </div>

          {/** Component used to display step-by-step instructions for texting 911*/}
          <TextInstructions/>

          {/** Component used to display the FAQ portion of the website. Given props are used to 
           *   describe the process of calculating the percentage of Text to 911 compatible PSAPs in the U.S. */}
          <FAQ
            firstDate={this.state.lastUpdated.get("compatiblePSAPsDate")}
            secondDate={this.state.lastUpdated.get("totalPSAPsDate")}
            compatiblePSAPs={this.state.psapMap.get("Compatible PSAPs")}
            totalPSAPs={this.state.psapMap.get("Total PSAPs")}
            countyTotal={this.state.countyCount}
          />
        </div>);
    }
}
 
export default Home;